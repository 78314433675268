<template>
  <div class="all">
    <el-table stripe :data="tableData">
      <el-table-column
        show-overflow-tooltip
        prop="title"
        label="标题"
        :width="type == 2?200:340"
      >
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top" :width="type == 2?200:340">
            <p>{{ scope.row.text }}</p>
            <div
              slot="reference"
              class="name-wrapper"
              style="white-space: pre-wrap"
            >
              {{ scope.row.title }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="workspaceStr"
        label="建议事项"
        sortable
        :filters="filterWorkspace"
        :filter-method="filterHandler"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="categoryStr"
        label="分类"
        width="100"
        sortable
        :filters="filterCategory"
        :filter-method="filterHandler"
      >
      </el-table-column>
      <el-table-column
        prop="subcategoryStr"
        label="子类"
        sortable
        :filters="filterSubcategory"
        :filter-method="filterHandler"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="belongCreatorName"
        label="创建人"
        width="120"
        sortable
        :filters="filterCreator"
        :filter-method="filterHandler"
      >
      </el-table-column>
      <el-table-column
        prop="creatorDeptName"
        v-if="type == 2"
        label="创建人部门"
        width="140"
        sortable
        :filters="filterCreatorDeptName"
        :filter-method="filterHandler"
      >
      </el-table-column>
      <el-table-column
        prop="statusStr"
        label="状态"
        width="100"
        sortable
        :filters="filterStatus"
        :filter-method="filterHandler"
      >
        <template slot-scope="scope">
          <el-tag size="medium" :type="scope.row.statusType">{{
            scope.row.statusStr
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="modified" label="更新日期" width="140" sortable>
      </el-table-column>
      <!-- <el-table-column label="操作" width="120">
      <template slot-scope="scope">
        <el-button size="mini" @click="handleView(scope.$index, scope.row)">查看详情</el-button>
      </template>
    </el-table-column>       -->
    </el-table>

    <el-row style="margin-top: 12px">
      <el-col style="text-align: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from 'axios'
export default {
  data() {
    return {
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false,
      user: window.globalData?.user || {},
      categoryList: window.globalData?.config.categoryList || [],
      threeClassDic: window.globalData?.config.threeClassDic || [],
      tagList: window.globalData?.config.tagList || [],
      statusList: window.globalData?.config.statusList || [],
      storyData: [],
      tableData: [],
      filterCategory: [],
      filterCreator: [],
      filterSubcategory: [],
      filterCreatorDeptName: [],
      filterWorkspace: [],
      filterStatus: [],
      type: '2', //1:我的部门 2：所有部门
    }
  },
  methods: {
    //调整当前的页码
    handleCurrentChange(pageNumber) {
      //数据重新分页
      this.getPageInfo({ page: pageNumber })
    },
    //分页时修改每页的行数,这里会自动传入一个size
    handleSizeChange(size) {
      //修改当前每页的数据行数
      this.queryParams.pageSize = size
      //数据重新分页
      this.getPageInfo({ page: 1 })
    },
    async getPageInfo({ page = 1 }) {
      this.queryParams = { ...this.queryParams, page }
      let url = `api/opinions/list?page=${this.queryParams.page}&pageSize=${this.queryParams.pageSize}`
      //如果是查询我的部门 则需要携带部门 id
      if (this.type == '1' && this.user.departmentId) {
        url += `&creatorDeptId=${this.user.departmentId}`
      }
      let { data } = await get(url)
      if (data.code === 0) {
        console.log('resp-list', data)
        let { records = [], total = 0 } = data.data
        this.storyData = records
        this.total = total
        this.tableData = this.storyData.map((item) => {
          let obj = {
            ...item,
          }
          //建议事项
          let worekSpaceIndex = this.categoryList.findIndex(
            (o) => item.workspaceId == o.workspace_id
          )
          obj.workspaceStr =
            worekSpaceIndex != -1 ? this.categoryList[worekSpaceIndex].name : ''
          if (worekSpaceIndex != -1) {
            //分类
            let categoryIndex = this.categoryList[
              worekSpaceIndex
            ].secondList.findIndex((o) => o.id == obj.category)
            let categoryStr =
              categoryIndex != -1
                ? this.categoryList[worekSpaceIndex].secondList[categoryIndex]
                    .name
                : ''
            obj.categoryStr = categoryStr
          } else {
            obj.categoryStr = ''
          }
          //子类
          let subcategoryIndex = this.threeClassDic.findIndex(
            (o) => o.id == item.type
          )
          obj.subcategoryStr =
            subcategoryIndex != -1
              ? this.threeClassDic[subcategoryIndex].name
              : ''

          //任务状态
          let statusIndex = this.statusList.findIndex(
            (o) => o.value == item.status
          )
          obj.statusStr =
            statusIndex != -1 ? this.statusList[statusIndex].label : ''
          obj.statusType = obj.statusStr
            ? this.tagList.find((o) => o.value == obj.statusStr).label
            : ''
          return obj
        })

        const workspaceSet = new Set() //建议事项
        const subcategorySet = new Set() //子类
        const categorySet = new Set()
        const creatorSet = new Set()
        const statusSet = new Set()
        const creatorDeptNameSet = new Set()
        console.log('set', categorySet, creatorSet, statusSet)
        this.tableData.forEach((item) => {
          categorySet.add(item.categoryStr)
          creatorSet.add(item.belongCreatorName)
          statusSet.add(item.statusStr)
          workspaceSet.add(item.workspaceStr)
          subcategorySet.add(item.subcategoryStr)
          creatorDeptNameSet.add(item.creatorDeptName)
        })
        const cates = []
        categorySet.forEach(function (cate) {
          var item = { text: cate, value: cate }
          console.log(item)
          cates.push(item)
        })
        const creators = []
        creatorSet.forEach(function (creator) {
          var item = { text: creator, value: creator }
          creators.push(item)
        })

        const statusArray = []
        statusSet.forEach(function (status) {
          var item = { text: status, value: status }
          statusArray.push(item)
        })
        const workspaceArray = []
        workspaceSet.forEach(function (row) {
          var item = { text: row, value: row }
          workspaceArray.push(item)
        })

        const subcategoryArray = []
        subcategorySet.forEach(function (row) {
          var item = { text: row, value: row }
          subcategoryArray.push(item)
        })

        const creatorDeptNameArray = []
        creatorDeptNameSet.forEach(function (row) {
          var item = { text: row, value: row }
          creatorDeptNameArray.push(item)
        })

        this.filterCategory = cates
        this.filterCreatorDeptName = creatorDeptNameArray
        this.filterCreator = creators
        this.filterStatus = statusArray
        this.filterWorkspace = workspaceArray
        this.filterSubcategory = subcategoryArray
        console.log(
          'dic-filter',
          cates,
          '---',
          creators,
          '---',
          statusArray,
          '---',
          workspaceArray,
          '---',
          subcategoryArray
        )
      } else {
        this.$message.error('读取列表失败!')
      }
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] == value
    },
  },
  watch: {
    $route: {
      handler() {
        //获取类型 1:我的部门 2：所有部门
        let { type = '2' } = this.$route.params
        this.type = type
        this.getPageInfo({ page: 1 })
      },
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style>
.all {
  padding: 40px;
  width: 1040px;
}
</style>
